import React from "react"
import { ReactComponent as Wordmark } from "../images/svgs/wordmark.svg"
import { ReactComponent as LabsWordmark } from "../images/svgs/workmark-labs.svg"
import { ReactComponent as Logo } from "../images/svgs/logo.svg"
import { ReactComponent as Close } from "../images/svgs/close.svg"
import { ReactComponent as Play } from "../images/svgs/play.svg"
import { ReactComponent as Pause } from "../images/svgs/pause.svg"
import { ReactComponent as Canada } from "../images/svgs/canada.svg"

const AllSvgs = {
  wordmark: Wordmark,
  labsWordmark: LabsWordmark,
  logo: Logo,
  close: Close,
  play: Play,
  pause: Pause,
  canada: Canada,
}

const getIconMarkup = svg => {
  return React.createElement(AllSvgs[svg])
}

function Svg({ svg, variation, className = "", ariaLabel, onClick }) {
  const kebabCaseName = svg.replace(
    /[A-Z]/g,
    letter => `-${letter.toLowerCase()}`
  )

  const standardStyle = `
    Svg Svg--${kebabCaseName} ${className}
    ${!!variation ? `Svg--${kebabCaseName}--${variation}` : ""}
  `
  return (
    <div className={standardStyle} aria-label={ariaLabel} onClick={onClick}>
      {getIconMarkup(svg)}
    </div>
  )
}

export default Svg
