import * as React from "react"
import { useEffect, useState } from "react"
import classNames from "classnames"
import jsonp from "jsonp"
import toQueryString from "to-querystring"
import Select from "react-select"

import Svg from "../components/svg"
import Seo from "../components/seo"

import LabsVideo from "../videos/labs.mp4"
// import TurnstileVideo from "../videos/turnstile.mp4"
import LabsPng from "../images/pngs/labs.png"
import Poster from "../images/pngs/poster.png"
import ArrowDown from "../images/pngs/arrowDown.png"
import { ApplicationForm } from "../components/application"

const Course = ({ title, code, description, date, time, person }) => {
  return (
    <div className="flex flex-col gap-y-6 lg:gap-y-10 lg:items-center">
      <p className="w-fit h-fit	border border-solid border-darkGrey px-3 py-0.5 uppercase font-euro rounded-full text-black bg-darkGrey text-xxs lg:text-xs">
        02 &#8212; {code}
      </p>
      <div className="flex flex-col lg:text-center lg:mt-8">
        <h2 className="font-euro uppercase text-2xl lg:text-[70px] leading-none mb-1">
          {title}
        </h2>
        {person && (
          <h3 className="font-euro uppercase text-xl lg:text-[40px] leading-none">
            <span>WITH </span>
            <span className="font-bold">{person}</span>
          </h3>
        )}
      </div>
      <div className="flex flex-col lg:flex-row lg:items-center lg:gap-x-2 font-bold font-euro">
        <p className="w-max text-xs lg:text-xl">{date}</p>
        <p className="w-max text-xs lg:text-xl">{time}</p>
      </div>
      <div className="text-sm lg:text-sm font-euro lg:text-center max-w-[1200px]">
        {description.map(line => {
          return <p className="mb-4">{line}</p>
        })}
      </div>
    </div>
  )
}

// Taken from: https://github.com/revolunet/react-mailchimp-subscribe/blob/master/src/index.js
const getAjaxUrl = url => url.replace("/post?", "/post-json?")
const url =
  "https://hxouse.us20.list-manage.com/subscribe/post?u=798992bbf6c66b23fa61da0c4&amp;id=cb223f53dc"

const options = [
  { value: "Creative Technology", label: "Creative Technology" },
  {
    value: "Software or Hardware Programming",
    label: "Software or Hardware Programming",
  },
  { value: "Science and Engineering", label: "Science and Engineering" },
  {
    value: "Interactive and Immersive Design",
    label: "Interactive and Immersive Design",
  },
  { value: "UX or UI", label: "UX or UI" },
  { value: "Visual Effects", label: "Visual Effects" },
  {
    value: "Augmented, Virtual or Mixed Reality",
    label: "Augmented, Virtual or Mixed Reality",
  },
]

const academicOptions = [
  { value: "No certificate or degree", label: "No certificate or degree" },
  { value: "High school certificate", label: "High school certificate" },
  {
    value: "College or other non-university certificate",
    label: "College or other non-university certificate",
  },
  {
    value: "University below bachelor level",
    label: "University below bachelor level",
  },
  {
    value: "University diploma or degree",
    label: "University diploma or degree",
  },
]

const customStyles = {
  indicatorsContainer: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#C5C5C5",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#C5C5C5",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
    outline: "none",
    boxShadow: "none",
    cursor: "pointer",
    minHeight: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "black",
    border: "1px solid white",
    borderRadius: "15px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#C5C5C5",
    // borderBottom: "1px solid white",
    backgroundColor: "rgba(0,0,0,0)",
    cursor: "pointer",
    "&:active": {
      backgroundColor: "rgba(0,0,0,0)",
    },
  }),
}

const IndexPage = () => {
  const [showForm, setShowForm] = useState(false)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [location, setLocation] = useState("")
  const [academic, setAcademic] = useState(null)
  const [skillset, setSkillset] = useState(null)

  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(true)
  const [showMessage, setShowMessage] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    const video = document.querySelector("#labsVideo")

    video?.addEventListener("playing", () => {
      setIsPlaying(true)
    })

    video?.addEventListener("pause", () => {
      setIsPlaying(false)
    })

    video?.addEventListener("volumechange", () => {
      setIsMuted(video.muted)
    })
  })

  const toggleMute = () => {
    const video = document.querySelector("#labsVideo")
    video.muted = !video.muted
  }

  const handlePlayback = () => {
    const video = document.querySelector("#labsVideo")

    if (isPlaying) {
      video?.pause()
    } else {
      video?.play()
    }
  }

  const toggleForm = () => {
    setShowForm(showForm => !showForm)
  }

  useEffect(() => {
    const HTMLElement = document.querySelector("html")
    if (showForm) {
      HTMLElement.style.overflow = "hidden"
      HTMLElement.style.position = "fixed"
    } else {
      HTMLElement.style.overflow = "auto"
      HTMLElement.style.position = "static"
    }
  }, [showForm])

  const submitHandler = e => {
    e.preventDefault()

    const params = toQueryString({
      EMAIL: email,
      FNAME:
        firstName.charAt(0).toUpperCase() +
        firstName.substring(1).toLowerCase(),
      LNAME:
        lastName.charAt(0).toUpperCase() + lastName.substring(1).toLowerCase(),
      LOCATION:
        location.charAt(0).toUpperCase() + location.substring(1).toLowerCase(),
      SKILLSET: skillset ? skillset.value : "",
      ACADEMIC: academic ? academic.value : "",
      tags: 2649889,
    })

    jsonp(
      getAjaxUrl(url) + "&" + params,
      {
        param: "c",
      },
      (err, data) => {
        setShowError(false)

        if (err) {
          setShowError(true)
        } else if (data.result !== "success") {
          setShowError(true)
        }

        toggleForm()
        setShowMessage(true)
        clearForms()

        setTimeout(() => {
          setShowMessage(false)
        }, 1500)
      }
    )
  }

  const firstNameHandler = e => {
    setFirstName(e.target.value)
  }

  const lastNameHandler = e => {
    setLastName(e.target.value)
  }

  const emailHandler = e => {
    setEmail(e.target.value)
  }

  const locationHandler = e => {
    setLocation(e.target.value)
  }
  const academicHandler = e => {
    setAcademic(e)
  }
  const skillsetHandler = e => {
    setSkillset(e)
  }

  const clearForms = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setLocation("")
    setAcademic(null)
    setSkillset(null)
  }

  // return (
  //   <>
  //     <Seo />
  //     <div className="w-screen h-screen grid place-content-center">
  //       <h1 className="font-euro">COMING SOON</h1>
  //     </div>
  //   </>
  // )

  return (
    <>
      <Seo />
      <svg height="0" width="0">
        <defs>
          <clipPath
            id="television"
            clipPathUnits="objectBoundingBox"
            transform="scale(0.002808988764, 0.002840909091)"
          >
            <path
              d="M95.8673671,4.93848998 C124.231199,2.09859659 152.595032,0.678649902 180.958864,0.678649902 C207.776719,0.678649902 234.594574,1.94802595 261.412429,4.48677804 L261.412429,4.48677776 C308.625156,8.95624071 346.205749,45.9726043 351.388868,93.1123218 C355.199121,127.766034 357.104248,155.621477 357.104248,176.67865 C357.104248,197.589194 355.225561,224.3167 351.468187,256.861169 L351.468187,256.861169 C346.334572,301.325946 312.278706,336.963679 268.092639,344.109441 C232.767307,349.822247 203.722715,352.67865 180.958864,352.67865 C157.644196,352.67865 126.748837,349.682342 88.2727845,343.689726 L88.2727844,343.689726 C42.3928562,336.543962 7.46749871,298.756908 3.94958606,252.457296 C2.05269405,227.492105 1.10424805,202.232556 1.10424805,176.67865 C1.10424805,148.692573 2.95279915,120.353439 6.64990135,91.6612496 L6.6499015,91.6612496 C12.5954146,45.5197631 49.5758583,9.57336965 95.8673671,4.93848998 Z"
              id="Path"
              stroke="#979797"
            ></path>
          </clipPath>
        </defs>
      </svg>
      <main className="relative overflow-auto w-full flex justify-center p-6 md:p-12 font-body main-bg">
        <div
          className={classNames("grid h-fit-content w-full gap-8", {
            "pointer-events-none": showForm,
          })}
        >
          <header className="max-w-[1578px] w-full flex justify-between items-center md:items-start justify-self-center">
            <div className="flex gap-2 md:gap-4 justify-center items-center">
              <img
                className="w-[24px] md:w-[32px] lg:w-[48px]"
                src={LabsPng}
                alt="Rotating Labs Logo"
              />
              <Svg
                svg="wordmark"
                className="text-grey w-[125px] md:w-[250px] lg:w-[325px]"
                ariaLabel="HXOUSE Wordmark"
              />
            </div>
            <Svg
              svg="labsWordmark"
              className="text-grey w-[125px] md:w-[250px] lg:w-[325px]"
              ariaLabel="HXOUSE Labs Wordmark"
            />
          </header>
          <section className="max-w-[1700px] w-full flex flex-col p-0 pb-0 lg:pb-4 items-center mx-auto">
            <div className="flex flex-col lg:h-videoSection items-center lg:justify-between w-full mb-8 lg:mb-16 lg:px-16">
              <div className="aspect-square relative flex flex-col items-center gap-4 w-full lg:w-auto lg:h-[40%] xl:h-[50%] xxl:h-[60%]">
                <div className="clip-path w-full lg:w-auto lg:h-full outline-none focus:outline-none focus-visible:outline-none">
                  <video
                    poster={Poster}
                    playsInline
                    autoPlay
                    muted
                    loop
                    id="labsVideo"
                    className="w-full lg:w-auto lg:h-full outline-none focus:outline-none focus-visible:outline-none"
                  >
                    <source src={LabsVideo} type="video/mp4" />
                  </video>
                </div>
                <button
                  className={classNames(
                    "z-50 text-xs md:text-sm font-body text-green font-bold opacity-1 transition-opacity duration-500",
                    { "opacity-0": showForm }
                  )}
                  onClick={toggleMute}
                >
                  {isMuted ? "UNMUTE" : "MUTE"}
                </button>
                <div
                  className="outline-none focus:outline-none focus-visible:outline-none cursor-pointer absolute top-0 left-0 w-full h-full flex justify-center items-center group"
                  onClick={handlePlayback}
                  role="button"
                  tabIndex={0}
                >
                  <Svg
                    svg={isPlaying ? "pause" : "play"}
                    ariaLabel={isPlaying ? "Pause" : "Play"}
                    className={classNames(
                      "w-[75px] h-[75px] hover:opacity-80 transition-opacity duration-500",
                      { "opacity-0": isPlaying },
                      { "opacity-80": !isPlaying }
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-col mt-12 lg:mt-4">
                <p className="max-w-[1200px] font-euro text-sm md:text-base text-center justify-self-center tracking-wide text-darkerGrey pb-4">
                  HXOUSE LABS will give next generation tech-creatives access to
                  industry leading knowledge, people and tools with unparalleled
                  educational experiences, where mentorship and technical
                  training will guide the development of next generation
                  technology creators.
                </p>
                <p className="max-w-[1200px] font-euro text-sm md:text-base text-center justify-self-center tracking-wide text-darkerGrey">
                  HXOUSE LABS is accepting tenants with disciplines in creative
                  technology, software or hardware programming, science and
                  engineering, interactive and immersive design, UX / UI, visual
                  effects and augmented / virtual / mixed reality. HXOUSE LABS
                  will provide Tenants with both a philosophical grounding and a
                  technological basis for developing world changing applications
                  and business ideas.
                </p>
              </div>
              <div className="flex-col items-center hidden lg:flex">
                <p className="font-euro font-bold text-sm pb-1 text-grey">
                  SCROLL DOWN FOR MORE
                </p>
                <img className="w-[16px]" src={ArrowDown} alt="Scroll down" />
              </div>
            </div>
            <div className="flex flex-col items-center text-grey mb-6">
              <div className="justify-between w-full lg:grid lg:grid-cols-2 flex mb-8 mt-8 lg:mt-0">
                <div className="flex gap-x-4 lg:justify-between items-center lg:w-[90%]">
                  <p className="w-fit	border border-solid border-grey px-4 py-0.5 uppercase font-euro rounded-full font-bold text-xs lg:text-lg">
                    PROGRAMMING
                  </p>
                  <p className="w-fit h-fit	border border-solid border-grey px-4 py-0.5 uppercase font-euro rounded-full font-bold text-xs lg:text-lg">
                    MODULE 02
                  </p>
                </div>
                <p className="w-fit h-fit	border border-solid border-grey px-4 py-0.5 uppercase font-euro rounded-full font-bold text-xs lg:text-lg lg:justify-self-end">
                  2024
                </p>
              </div>
              <h2 className="uppercase tracking-tight leading-none text-6xl lg:text-[130px] xl:text-[180px] font-euro mb-8 text-center lg:text-justify flex flex-col items-center">
                <span className="tracking-mobile lg:tracking-widest -mr-[0.35em] lg:mr-0">
                  MIRROR
                </span>
                <span> WORLDS</span>
              </h2>
              <div className="flex flex-col max-w-[1200px] lg:text-center lg:items-center mx-auto">
                <p className="mb-0 text-sm font-bold font-euro text-darkerGrey lg:text-grey">
                  HXOUSE LABS PRESENTS MIRROR WORLDS
                </p>
                <p className="mb-4 text-sm font-bold font-euro text-darkerGrey lg:text-grey">
                  A program that focuses on the interface between the digital
                  and physical world. We will explore the collision between
                  digital and physical by examining how emerging technologies
                  are changing our experience of the places and spaces that we
                  inhabit.
                </p>
                <p className="mb-8 lg:mb-20 text-sm font-bold font-euro text-darkerGrey lg:text-grey">
                  From digital worlds and decentralized systems to smart cities
                  and ‘The Internet of Everything,’ Mirror Worlds aims to
                  develop and experiment with contemporary thinking and
                  state-of-the-art prototypes, in order to question and define
                  the relationship between people and the environment that
                  surrounds them.
                </p>
              </div>
              <Course
                title="HABITATS"
                // person=""
                code="E"
                description={[
                  "“Habitat” - the environment of a person that includes both living and nonliving elements.",
                  "With the convergence of groundbreaking technologies, such as artificial intelligence, and a shift in the way we live and work following the pandemic, the perception of our habitat has transformed radically. As quantum computing enters the mainstream, habitats of the future will be less human-centred by transforming into places where living and non -living elements co-exist.",
                  "In this workshop, participants will journey through both the tangible and intangible realms of habitats. Facilitated by a diverse team of experts, tenants will use Unreal Engine 5 to model, simulate, and bring to life their visions of future habitats. Through cross-disciplinary dialogues, we will explore the merge of technology and humanity to offer fresh perspectives on these ever evolving concepts. Together, we’ll bridge the gap between our human roots and the digital frontier, reimagining the very essence of what we call a “habitat”.",
                ]}
                date="FEBRUARY 10 & 11"
                time="9:00 AM — 6:00 PM"
              />
              <div className="mt-4 lg:mt-10 w-full lg:w-fit lg:mx-auto text-center text-base lg:text-lg xl:text-xl tracking-wider font-euro font-bold uppercase bg-green py-2 px-8 text-black rounded-[30px] border-2 border-green border-solid ">
                APPLICATIONS NOW OPEN
              </div>
              <ApplicationForm />
              <div className="mt-4 lg:mt-6 text-base lg:text-lg font-euro w-fit mx-0">
                ADDITIONAL PROGRAMMING COMING SOON
              </div>
            </div>
          </section>
          <div className="max-w-[1578px] w-full self-end relative justify-self-center lg:hidden">
            <button
              className="w-full font-euro text-grey rounded-xl appearance-none leading-tight"
              type="button"
              form="labs-form"
              onClick={showForm ? submitHandler : toggleForm}
            >
              REGISTER FOR THE
              <br />
              HXOUSE LABS PORTAL
            </button>
            <p className="uppercase text-center text-xxs text-grey mt-2 font-euro">
              HXOUSE LABS WILL CONTINUE TO ROLL OUT ADDITIONAL PROGRAMMING
              THROUGHOUT 2023—2024. REGISTER TO OUR PORTAL TO BE THE FIRST TO
              KNOW WHEN NEW programs &amp; workshops are AVAILABLE.
            </p>
          </div>
          <footer className="max-w-[1578px] w-full grid grid-cols-3 items-end h-fit-content self-end justify-self-center">
            <div className="flex gap-3 md:gap-5 text-4xs md:text-2xs">
              <Svg
                svg="logo"
                className="text-grey w-[23px] md:w-[45px]"
                ariaLabel="HXOUSE Logo"
              />
              <div>
                <p className="font-bold">2024 HXOUSE INC.</p>
                <p className="font-bold mb-2 md:mb-4">SPECIAL PROGRAMMING</p>
                <a
                  href="https://policy.hxouse.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-grey"
                >
                  PRIVACY POLICY
                </a>
              </div>
            </div>
            <div>
              <div className="hidden lg:block relative h-full w-full text-center">
                <button
                  className="text-center font-euro w-fit-content text-grey px-10 h-full mb-2"
                  type="button"
                  form="labs-form"
                  onClick={toggleForm}
                >
                  REGISTER FOR THE HXOUSE LABS PORTAL
                </button>
              </div>
              <p className="hidden lg:block text-xxs uppercase text-center font-euro text-grey">
                HXOUSE LABS WILL CONTINUE TO ROLL OUT ADDITIONAL PROGRAMMING
                THROUGHOUT 2023—2024. REGISTER TO OUR PORTAL TO BE THE FIRST TO
                KNOW WHEN NEW programs &amp; workshops are AVAILABLE.
              </p>
            </div>
            <a
              className="justify-self-end"
              href="https://www.feddevontario.gc.ca/eic/site/723.nsf/eng/home"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Svg
                svg="canada"
                className="text-grey w-[50px] md:w-[100px]"
                ariaLabel="Canada Logo"
              />
            </a>
          </footer>
        </div>
        <section
          className={classNames("w-full h-screen z-50 fixed bottom-0 left-0", {
            "pointer-events-none": !showForm,
          })}
        >
          <div
            className={classNames(
              "outline-none focus:outline-none focus-visible:outline-none transition-opacity duration-500",
              { "opacity-0 pointer-events-none": !showForm },
              { "opacity-1 pointer-events-auto": showForm }
            )}
          >
            <form
              id="labs-form"
              // onSubmit={submitHandler}
              className="font-euro absolute top-0 left-0 h-full w-full flex flex-col text-sm md:text-base gap-8 md:gap-16 justify-center items-center bg-dark"
            >
              <input
                className="w-screen md:w-full caret-grey text-center placeholder:text-center bg-transparent text-grey placeholder:text-grey uppercase focus:placeholder:text-transparent focus:outline-none focus-visible:outline-none"
                placeholder="First Name"
                value={firstName}
                onChange={firstNameHandler}
                aria-label="First Name"
                type="text"
              />
              <input
                className="w-screen md:w-full caret-grey text-center placeholder:text-center bg-transparent text-grey placeholder:text-grey uppercase focus:placeholder:text-transparent focus:outline-none focus-visible:outline-none"
                placeholder="Last Name"
                value={lastName}
                onChange={lastNameHandler}
                aria-label="Last Name"
                type="text"
              />
              <input
                className="w-screen md:w-full caret-grey text-center placeholder:text-center bg-transparent text-grey placeholder:text-grey uppercase focus:placeholder:text-transparent focus:outline-none focus-visible:outline-none"
                placeholder="Email Address"
                value={email}
                onChange={emailHandler}
                aria-label="Email"
                type="email"
              />
              <input
                className="w-screen md:w-full caret-grey text-center placeholder:text-center bg-transparent text-grey  placeholder:text-grey uppercase focus:placeholder:text-transparent focus:outline-none focus-visible:outline-none"
                placeholder="Location"
                value={location}
                onChange={locationHandler}
                aria-label="Location"
                type="text"
              />
              <Select
                isSearchable={false}
                className="w-5/6 md:w-1/4 caret-grey text-center uppercase"
                options={options}
                styles={customStyles}
                placeholder="DIGITAL SKILLSET (DISCIPLINE)"
                onChange={skillsetHandler}
                value={skillset}
                us
              />
              <Select
                isSearchable={false}
                className="w-5/6 md:w-1/4 caret-grey text-center uppercase"
                options={academicOptions}
                styles={customStyles}
                placeholder="ACADEMIC COMPETENCY"
                onChange={academicHandler}
                value={academic}
              />
              <button
                className="mt-4 text-xs md:text-base w-max-content font-euro font-bold text-grey px-10 py-5 rounded-xl border-2 border-grey border-solid"
                type="submit"
                onClick={submitHandler}
              >
                REGISTER TO HXOUSE LABS
              </button>
            </form>
            <Svg
              onClick={toggleForm}
              svg="close"
              className="absolute top-[100px] right-[25px] md:top-[25px] md:right-[25px] text-grey cursor-pointer w-[40px]"
              ariaLabel="HXOUSE Labs Wordmark"
            />
          </div>
          <div className="outline-none focus:outline-none focus-visible:outline-none fixed top-0 left-0 w-full h-full pointer-events-none flex items-center justify-center">
            <h1
              className={classNames(
                "text-base font-euro font-bold text-center p-4 md:p-6 bg-green text-black uppercase rounded-xl transition-opacity duration-700",
                { "opacity-100": showMessage },
                { "opacity-0": !showMessage }
              )}
            >
              {showError ? "Please try again" : "Thank you for subscribing"}
            </h1>
          </div>
        </section>
      </main>
    </>
  )
}

export default IndexPage
