import React, { useState } from "react"

export const ApplicationForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [cd, setCD] = useState("---")
  const [handle, setHandle] = useState("")
  const [link, setLink] = useState("")

  const [message, setMessage] = useState("")
  const [submitButton, setSubmitButton] = useState("APPLY")

  const nameHandler = e => {
    setName(e.target.value)
  }
  const emailHandler = e => {
    setEmail(e.target.value)
  }
  const cdHandler = e => {
    setCD(e.target.value)
  }
  const handleHandler = e => {
    setHandle(e.target.value)
  }
  const linkHandler = e => {
    setLink(e.target.value)
  }

  const clearInputs = () => {
    setName("")
    setEmail("")
    setCD("")
    setHandle("")
    setLink("")
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (!name || !email || !cd || !link || !handle) {
      setMessage("PLEASE FILL OUT REQUIRED FIELDS")
      return
    }

    const postData = {
      name,
      email,
      creativeDiscipline: cd,
      socialMediaHandle: handle,
      link,
    }

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
      body: JSON.stringify(postData),
    }

    const apiUrl =
      "https://script.google.com/macros/s/AKfycbw1E47GOCGjvGq5fiEo_3XkIlXe7fLKRN9b2Db0O9bOZHkjOgCArgbaJZp71yR9b-bV/exec"

    setSubmitButton("SUBMITTING...")

    fetch(apiUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        clearInputs()
        setSubmitButton("APPLY")
        setMessage(
          "YOUR APPLICATION HAS BEEN SUBMITTED AND WILL BE REVIEWED BY HXOUSE."
        )
      })
      .catch(error => {
        setMessage("THERE WAS AN ERROR IN SUBMITTING, TRY AGAIN")
        setSubmitButton("APPLY")
      })
  }

  return (
    <form
      className="w-full max-w-full lg:max-w-[700px] flex flex-col my-10 lg:my-12"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col mb-4">
        <label
          className={`font-euro font-bold text-base mb-1 leading-none ${
            message === "PLEASE FILL OUT REQUIRED FIELDS" && name === ""
              ? "text-red"
              : "text-grey"
          } flex flex-col lg:flex-row lg:items-center lg:justify-between`}
          htmlFor="name"
        >
          <span>NAME*</span>
          {message === "PLEASE FILL OUT REQUIRED FIELDS" && name === "" ? (
            <span className="text-xs">THIS FIELD IS REQUIRED</span>
          ) : null}
        </label>
        <input
          value={name}
          id="name"
          onChange={nameHandler}
          className={`w-full uppercase font-euro font-bold appearance-none border-solid border ${
            message === "PLEASE FILL OUT REQUIRED FIELDS" && name === ""
              ? "border-red"
              : "border-grey"
          } rounded-sm bg-transparent focus:outline-none focus-visible:outline-none px-1`}
        />
      </div>
      <div className="flex flex-col mb-4">
        <label
          className={`font-euro font-bold text-base mb-1 leading-none ${
            message === "PLEASE FILL OUT REQUIRED FIELDS" && email === ""
              ? "text-red"
              : "text-grey"
          } flex flex-col lg:flex-row lg:items-center lg:justify-between`}
          htmlFor="email"
        >
          E-MAIL*
          {message === "PLEASE FILL OUT REQUIRED FIELDS" && email === "" ? (
            <span className="text-xs">THIS FIELD IS REQUIRED</span>
          ) : null}
        </label>
        <input
          value={email}
          id="email"
          onChange={emailHandler}
          className={`w-full uppercase font-euro font-bold appearance-none border-solid border ${
            message === "PLEASE FILL OUT REQUIRED FIELDS" && email === ""
              ? "border-red"
              : "border-grey"
          } rounded-sm bg-transparent focus:outline-none focus-visible:outline-none px-1`}
        />
      </div>
      <div className="flex flex-col mb-4">
        <label
          className={`font-euro font-bold text-base mb-1 leading-none ${
            message === "PLEASE FILL OUT REQUIRED FIELDS" && cd === "---"
              ? "text-red"
              : "text-grey"
          } flex flex-col lg:flex-row lg:items-center lg:justify-between`}
          htmlFor="cd"
        >
          CREATIVE DISCIPLINE*
          {message === "PLEASE FILL OUT REQUIRED FIELDS" && cd === "---" ? (
            <span className="text-xs">THIS FIELD IS REQUIRED</span>
          ) : null}
        </label>
        <select
          value={cd}
          id="cd"
          onChange={cdHandler}
          className={`w-full uppercase font-euro font-bold appearance-none border-solid border ${
            message === "PLEASE FILL OUT REQUIRED FIELDS" && cd === "---"
              ? "border-red"
              : "border-grey"
          } rounded-sm bg-transparent focus:outline-none focus-visible:outline-none px-1`}
        >
          <option value="">---</option>
          <option value="Creative Technology">Creative Technology</option>
          <option value="Software or Hardware Programming">
            Software or Hardware Programming
          </option>
          <option value="Science and Engineering">
            Science and Engineering
          </option>
          <option value="Interactive and Immersive Design">
            Interactive and Immersive Design
          </option>
          <option value="UX / UI Designer">UX / UI Designer</option>
          <option value="3D,Visual Effects, VR, XR, and AR">
            3D, Visual Effects, VR, XR, and AR
          </option>
          <option value="Visual Artist">Visual Artist</option>
          <option value="Marketing and Strategy">Marketing and Strategy</option>
          <option value="Creative Design">Creative Design</option>
          <option value="Graphic Design">Graphic Design</option>\
          <option value="Other">Other</option>
        </select>
      </div>
      <div className="flex flex-col mb-4">
        <label
          className={`font-euro font-bold text-base mb-1 leading-none ${
            message === "PLEASE FILL OUT REQUIRED FIELDS" && handle === ""
              ? "text-red"
              : "text-grey"
          } flex flex-col lg:flex-row lg:items-center lg:justify-between`}
          htmlFor="handle"
        >
          SOCIAL MEDIA HANDLE*
          {message === "PLEASE FILL OUT REQUIRED FIELDS" && handle === "" ? (
            <span className="text-xs">THIS FIELD IS REQUIRED</span>
          ) : null}
        </label>
        <input
          value={handle}
          id="handle"
          onChange={handleHandler}
          className={`w-full uppercase font-euro font-bold appearance-none border-solid border ${
            message === "PLEASE FILL OUT REQUIRED FIELDS" && handle === ""
              ? "border-red"
              : "border-grey"
          } rounded-sm bg-transparent focus:outline-none focus-visible:outline-none px-1`}
        />
      </div>
      <div className="flex flex-col mb-8">
        <label
          className={`font-euro font-bold text-base mb-1 leading-none ${
            message === "PLEASE FILL OUT REQUIRED FIELDS" && link === ""
              ? "text-red"
              : "text-grey"
          } flex flex-col lg:flex-row lg:items-center lg:justify-between`}
          htmlFor="link"
        >
          UPLOAD A LINK TO YOUR PORTFOLIO / CV / RELEVANT WORK*
          {message === "PLEASE FILL OUT REQUIRED FIELDS" && link === "" ? (
            <span className="text-xs">THIS FIELD IS REQUIRED</span>
          ) : null}
        </label>
        <input
          value={link}
          id="link"
          onChange={linkHandler}
          className={`w-full uppercase font-euro font-bold appearance-none border-solid border ${
            message === "PLEASE FILL OUT REQUIRED FIELDS" && link === ""
              ? "border-red"
              : "border-grey"
          } rounded-sm bg-transparent focus:outline-none focus-visible:outline-none px-1`}
        />
      </div>

      <p className="uppercase font-bold font-euro text-grey text-base">
        {message}
      </p>

      <button
        type="submit"
        className="w-fit mt-8 mx-auto lg:mx-0 text-center text-base font-euro font-bold uppercase bg-grey py-1 px-4 text-black rounded-[30px] border-2 border-grey border-solid transition-all duration-300 hover:bg-green hover:text-black hover:border-green lg:text-black lg:bg-grey"
      >
        {submitButton}
      </button>
    </form>
  )
}
